<div class="inherit-dimensions relative">
  @if ((imageLoaded$ | async) === false) {
    <div class="absolute left-1/2 top-1/2">
      <mat-spinner></mat-spinner>
    </div>
  }
  <img
    [height]="imageHeight()"
    [src]="data.photo.original"
    [width]="imageWidth()"
    class="object-contain"
    (load)="imageLoad($event)"
  />
  @if (imageLoaded$ | async) {
    <div class="dark-button absolute bottom-4 right-4 flex flex-col gap-4">
      @if (canShareImage) {
        <button (click)="shareImage()" mat-fab>
          <mat-icon svgIcon="icon-share-3"></mat-icon>
        </button>
      }
      <a [href]="data.photo.original" mat-fab target="_blank" download>
        <mat-icon svgIcon="icon-download"></mat-icon>
      </a>
    </div>
  }
</div>
