<a
  matRipple
  class="my-1 inline-flex w-fit flex-row items-center gap-1 rounded-3xl bg-gray-200 py-0.5 pl-2 pr-3"
  routerLink="/events/{{ id }}"
>
  @if (icon) {
    <img [ngSrc]="icon | iconURL" width="24" height="24" class="h-6 w-6" />
  }
  <span class="leading-tight">{{ title }}</span>
</a>
