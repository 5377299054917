<div class="bg-white">
  <div
    class="bg-cover bg-center flex items-center justify-center text-white h-screen"
    [style.backgroundImage]="'url(\'/assets/images/home/vubHero.jpg\')'"
  >
    <div class="text-center pl-8 pr-8">
      <h1 class="text-3xl md:text-6xl font-semibold">ESN VUB EhB Brussels</h1>
      <p class="text-sm md:text-lg tracking-tight"
        ><i>"Students helping Students in the Heart of Europe"</i></p
      >
    </div>
  </div>
  <div
    class="bg-white text-black p-8 pb-4 pt-4 min-h-screen flex items-center justify-center text-center"
  >
    <div class="max-w-3xl">
      <h2 class="text-4xl md:text-5xl font-semibold mb-10">Who are we?</h2>
      <p class="text-sm md:text-lg md:text-justify">
        <span class="font-semibold">The Erasmus Student Network (ESN) </span> is
        a vibrant association that serves as a bridge between local and
        international communities. Our primary mission is to offer international
        students a unique opportunity to explore the captivating city of
        Brussels and the rich tapestry of Belgium through the insights of
        locals. To fulfill this goal, we curate an array of diverse activities,
        ranging from city trips and escape rooms to lively karaoke nights,
        unforgettable parties, and delightful dinners. At ESN, we believe that
        everything is possible when it comes to creating memorable experiences
        for our members. <br />
        <br />

        What sets us apart is our connection to a broader international network,
        spanning across 44 countries. In Belgium, ESN boasts 18 local sections,
        with five of them based right here in Brussels. This local presence
        ensures that we have a deep understanding of the city's unique culture,
        enabling us to provide international students with the most authentic
        and enriching experience during their time in the heart of Europe.
      </p>
    </div>
  </div>
  <div class="flex flex-col md:flex-row text-white">
    <div class="md:w-1/2">
      <img
        src="/assets/images/home/vubCard.jpg"
        alt="Image"
        class="w-full h-auto"
      />
    </div>
    <div
      class="md:w-1/2 bg-[#EC008C] flex flex-col items-center justify-center text-center"
    >
      <h3 class="text-4xl md:text-5xl font-semibold mb-8 mt-8">ESNcard</h3>
      <p class="text-sm md:text-md md:text-justify pl-8 pr-8 md:pl-20 md:pr-20">
        The <span class="font-semibold">ESNcard</span> is the lifeblood of our
        mission. It's a membership card that opens doors to a world of
        opportunities for international students. With an ESNcard, students
        become part of our global community, connecting them with like-minded
        individuals.
        <br />
        <br />
        More than just a card, it's a key to exclusive advantages like
        transportation and accommodation discounts, local partnerships, and
        cultural event perks. Our goal is to make international students'
        experiences more affordable and enjoyable, helping them fully embrace
        their host country's culture and community. The ESNcard isn't just a
        practical tool; it's a symbol of unity and friendship, enhancing the
        international student journey.
      </p>
      <a
        href="https://vub-ehb.esn.world/events/b857dc49-9e36-43d2-b510-dafb22221526/"
        class="bg-white font-semibold text-[#EC008C] rounded-full py-2 px-4 mt-8 mb-8"
      >
        Get your card here!
      </a>
    </div>
  </div>
  <div class="flex flex-col-reverse md:flex-row text-white">
    <div
      class="md:w-1/2 bg-[#7AC143] flex flex-col items-center justify-center text-center"
    >
      <h3 class="text-4xl md:text-5xl font-semibold mb-8 mt-8"
        >Buddy Program</h3
      >
      <p class="text-sm md:text-md md:text-justify pl-8 pr-8 md:pl-20 md:pr-20">
        As an international student, you have the opportunity to connect with a
        local buddy who will be your guide to both Brussels and VUB or EhB, our
        esteemed universities. This friendly match will introduce you to all
        aspects of the city and the campus, offering valuable insights and
        helping you navigate your academic journey while forging a new and
        lasting friendship.
        <br />
        <br />
        If you're a local student, you can also contribute to this exciting
        cultural exchange. By signing up through the provided link, you can
        become a welcoming friend for incoming international students,
        showcasing your knowledge of Brussels and EhB or VUB, and helping
        newcomers feel at home. It's a chance to expand your horizons, make
        global connections, and enrich your university experience.
      </p>
      <a
        href="https://buddysystem.eu/en/"
        class="bg-white font-semibold text-[#7AC143] rounded-full py-2 px-4 mt-8 mb-8"
      >
        Join the program!
      </a>
    </div>
    <div class="md:w-1/2">
      <img
        src="/assets/images/home/vubBuddy.jpg"
        alt="Image"
        class="w-full h-auto"
      />
    </div>
  </div>

  <footer class="relative p-4 pt-12 md:p-8">
    <app-technical-support></app-technical-support>
  </footer>
</div>
