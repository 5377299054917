@if (event) {
  <div class="block max-w-full overflow-x-auto">
    <table class="min-w-full select-all divide-y divide-gray-300">
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr>
          <td
            class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
            colspan="2"
          >
            Event Name:
          </td>
          <td
            [attr.colspan]="
              3 + event.submissionItems.length + formConfig.length
            "
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          >
            {{ event.title }}
          </td>
        </tr>
        <tr>
          <td
            class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
            colspan="2"
          >
            Date:
          </td>
          <td
            [attr.colspan]="
              3 + event.submissionItems.length + formConfig.length
            "
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          >
            {{ event.start | date }}
          </td>
        </tr>
        <tr>
          <td
            class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
            colspan="2"
          >
            Organizers:
          </td>
          <td
            [attr.colspan]="
              3 + event.submissionItems.length + formConfig.length
            "
            class="px-3 py-4 text-sm text-gray-500"
          >
            {{ joinOrganizers(event.organizerRegistrations) }}
          </td>
        </tr>
        <tr>
          <td
            class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
            colspan="2"
          >
            Participants (including organizers):
          </td>
          <td
            [attr.colspan]="
              3 + event.submissionItems.length + formConfig.length
            "
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          >
            {{
              event.participantRegistrationCount +
                event.organizerRegistrations.length
            }}
          </td>
          <td
            [attr.colspan]="event.submissionItems.length + formConfig.length"
          ></td>
        </tr>
        <tr>
          <th
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            scope="col"
          ></th>
          <th
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            scope="col"
          >
            Name
          </th>
          <th
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            scope="col"
          >
            E-mail
          </th>

          <th
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            scope="col"
          >
            Phone
          </th>
          <th
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            scope="col"
          >
            Check in
          </th>
          @for (submissionItem of event.submissionItems; track submissionItem) {
            <th
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              scope="col"
            >
              {{ submissionItem.name }}
            </th>
          }
          @for (field of formConfig; track field) {
            <th
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              scope="col"
            >
              {{ field.label }}
            </th>
          }
        </tr>
        @for (
          registration of filterRegistrations(event.participantRegistrations);
          track registration;
          let i = $index;
          let last = $last
        ) {
          <tr class="table-row">
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ i + 1 }}
            </td>
            <td
              class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
            >
              {{ registration.user.fullName }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ registration.user.email }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ registration.user.phone }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ registration.checkInTime | date: 'short' }}
            </td>
            @for (
              submissionItem of event.submissionItems;
              track submissionItem
            ) {
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ getSubmissionValue(registration, submissionItem) }}
              </td>
            }
            @for (field of formConfig; track field) {
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ getAdditionalDataValue(registration, field) }}
              </td>
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
}
