<div
  class="mt-auto flex w-full flex-col items-center gap-4 pb-8 pt-12 text-base text-slate-500"
>
  <div class="flex w-full justify-center gap-6">
    @for (link of (tenantInfo$ | async)?.settings?.socialLinks; track link) {
      <a [href]="link.url" target="_blank" class="hover:opacity-70">
        <img
          [ngSrc]="link.icon | iconURL"
          width="24"
          height="24"
          [alt]="link.label"
          class="h-6"
        />
      </a>
    }
  </div>
  <div class="text-center text-xs">
    <a routerLink="/page/faq" class="underline">FAQ</a> |
    <a routerLink="/page/imprint" class="underline">Imprint</a> |
    <a routerLink="/page/privacy" class="underline">Privacy Policy</a>
  </div>
</div>
