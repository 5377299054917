<div class="flex flex-col flex-wrap gap-4 md:flex-row">
  @for (submissionItem of registrationStats$ | async; track submissionItem) {
    <div class="flex flex-row items-center gap-1">
      <div class="flex-1">
        {{ submissionItem.submissionItemName }}
        <ul class="list-inside list-disc">
          @for (choice of submissionItem.choiceCounts; track choice) {
            <li> {{ choice.choice }}: {{ choice.count }} </li>
          }
        </ul>
      </div>
    </div>
  }
</div>
