<div
  class="app-event-list h-full rounded-2xl bg-secondary-container p-4 text-secondary-onContainer shadow-xl"
>
  <div class="flex flex-row items-center">
    <mat-icon
      svgIcon="icon-overtime"
      class="card-icon bg-secondary-on text-secondary-default"
    ></mat-icon>
    <h2 class="font-bold">
      {{ title }}
      @if (events) {
        <span class="font-light">({{ events.length }})</span>
      }
    </h2>
  </div>
  <mat-nav-list>
    @if (!isOrganized) {
      <div class="mx-4 my-2">
        <button
          class="w-full"
          color="primary"
          mat-flat-button
          (click)="requestClaimDialog()"
        >
          <mat-icon svgIcon="icon-cancel-4-digits"></mat-icon>
          Claim event with code
        </button>
      </div>
    }
    @if (events) {
      @for (event of futureEvents(); track event) {
        <app-event-list-item [event]="event"></app-event-list-item>
      }
    }
    @if (!events) {
      @for (event of [].constructor(5); track event) {
        <app-event-list-item></app-event-list-item>
      }
    }
    @if (events && pastEvents().length > 0) {
      <mat-expansion-panel>
        <mat-expansion-panel-header matRipple>
          <mat-panel-title>
            <div class="lg flex flex-row items-center gap-4 p-2">
              <mat-icon
                svgIcon="icon-time-machine"
                class="card-icon card-icon-small bg-secondary-on text-secondary-default"
              ></mat-icon>
              <p class="font-bold">Past events</p>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        @for (event of pastEvents(); track event) {
          <app-event-list-item [event]="event"></app-event-list-item>
        }
      </mat-expansion-panel>
    }
  </mat-nav-list>
</div>
