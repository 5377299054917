import { InjectionToken, inject } from '@angular/core';
import { Observable, share, fromEvent, startWith, map, distinctUntilChanged, shareReplay } from 'rxjs';
import { DOCUMENT } from '@angular/common';
const WA_WINDOW = new InjectionToken('[WA_WINDOW]', {
  factory: () => {
    const {
      defaultView
    } = inject(DOCUMENT);
    if (!defaultView) {
      throw new Error('Window is not available');
    }
    return defaultView;
  }
});
/**
 * @deprecated: drop in v5.0, use {@link WA_WINDOW}
 */
const WINDOW = WA_WINDOW;
const WA_ANIMATION_FRAME = new InjectionToken('[WA_ANIMATION_FRAME]', {
  factory: () => {
    const {
      requestAnimationFrame,
      cancelAnimationFrame
    } = inject(WINDOW);
    const animationFrame$ = new Observable(subscriber => {
      let id = NaN;
      const callback = timestamp => {
        subscriber.next(timestamp);
        id = requestAnimationFrame(callback);
      };
      id = requestAnimationFrame(callback);
      return () => {
        cancelAnimationFrame(id);
      };
    });
    return animationFrame$.pipe(share());
  }
});
/**
 * @deprecated: drop in v5.0, use {@link WA_ANIMATION_FRAME}
 */
const ANIMATION_FRAME = WA_ANIMATION_FRAME;
const WA_CACHES = new InjectionToken('[WA_CACHES]', {
  factory: () => inject(WINDOW).caches
});
/**
 * @deprecated: drop in v5.0, use {@link WA_CACHES}
 */
const CACHES = WA_CACHES;
const WA_CRYPTO = new InjectionToken('[WA_CRYPTO]', {
  factory: () => inject(WINDOW).crypto
});
/**
 * @deprecated: drop in v5.0, use {@link WA_CRYPTO}
 */
const CRYPTO = WA_CRYPTO;
const WA_CSS = new InjectionToken('[WA_CSS]', {
  factory: () => inject(WINDOW).CSS ?? {
    escape: v => v,
    // eslint-disable-next-line no-restricted-syntax
    supports: () => false
  }
});
/**
 * @deprecated: drop in v5.0, use {@link WA_CSS}
 */
const TOKEN_CSS = WA_CSS;
const WA_HISTORY = new InjectionToken('[WA_HISTORY]', {
  factory: () => inject(WINDOW).history
});
/**
 * @deprecated: drop in v5.0, use {@link WA_HISTORY}
 */
const HISTORY = WA_HISTORY;
const WA_LOCAL_STORAGE = new InjectionToken('[WA_LOCAL_STORAGE]', {
  factory: () => inject(WINDOW).localStorage
});
/**
 * @deprecated: drop in v5.0, use {@link WA_LOCAL_STORAGE}
 */
const LOCAL_STORAGE = WA_LOCAL_STORAGE;
const WA_LOCATION = new InjectionToken('[WA_LOCATION]', {
  factory: () => inject(WINDOW).location
});
/**
 * @deprecated: drop in v5.0, use {@link WA_LOCATION}
 */
const LOCATION = WA_LOCATION;
const WA_NAVIGATOR = new InjectionToken('[WA_NAVIGATOR]', {
  factory: () => inject(WINDOW).navigator
});
/**
 * @deprecated: drop in v5.0, use {@link WA_NAVIGATOR}
 */
const NAVIGATOR = WA_NAVIGATOR;
const WA_MEDIA_DEVICES = new InjectionToken('[WA_MEDIA_DEVICES]', {
  factory: () => inject(NAVIGATOR).mediaDevices
});
/**
 * @deprecated: drop in v5.0, use {@link WA_MEDIA_DEVICES}
 */
const MEDIA_DEVICES = WA_MEDIA_DEVICES;
const WA_NETWORK_INFORMATION = new InjectionToken('[WA_NETWORK_INFORMATION]', {
  // @ts-ignore
  factory: () => inject(WA_NAVIGATOR).connection || null
});
/**
 * @deprecated: drop in v5.0, use {@link WA_NETWORK_INFORMATION}
 */
const NETWORK_INFORMATION = WA_NETWORK_INFORMATION;
const WA_PAGE_VISIBILITY = new InjectionToken('[WA_PAGE_VISIBILITY]', {
  factory: () => {
    const documentRef = inject(DOCUMENT);
    return fromEvent(documentRef, 'visibilitychange').pipe(startWith(0), map(() => documentRef.visibilityState !== 'hidden'), distinctUntilChanged(), shareReplay({
      refCount: false,
      bufferSize: 1
    }));
  }
});
/**
 * @deprecated: drop in v5.0, use {@link WA_PAGE_VISIBILITY}
 */
const PAGE_VISIBILITY = WA_PAGE_VISIBILITY;
const WA_PERFORMANCE = new InjectionToken('[WA_PERFORMANCE]', {
  factory: () => inject(WINDOW).performance
});
/**
 * @deprecated: drop in v5.0, use {@link WA_PERFORMANCE}
 */
const PERFORMANCE = WA_PERFORMANCE;
const WA_SCREEN = new InjectionToken('[WA_SCREEN]', {
  factory: () => inject(WINDOW).screen
});
/**
 * @deprecated: drop in v5.0, use {@link WA_SCREEN}
 */
const SCREEN = WA_SCREEN;
const WA_SESSION_STORAGE = new InjectionToken('[WA_SESSION_STORAGE]', {
  factory: () => inject(WINDOW).sessionStorage
});
/**
 * @deprecated: drop in v5.0, use {@link WA_SESSION_STORAGE}
 */
const SESSION_STORAGE = WA_SESSION_STORAGE;
const WA_SPEECH_RECOGNITION = new InjectionToken('[WA_SPEECH_RECOGNITION]: [SPEECH_RECOGNITION]', {
  factory: () => {
    const windowRef = inject(WINDOW);
    return windowRef.speechRecognition || windowRef.webkitSpeechRecognition || null;
  }
});
/**
 * @deprecated: drop in v5.0, use {@link WA_SPEECH_RECOGNITION}
 */
const SPEECH_RECOGNITION = WA_SPEECH_RECOGNITION;
const WA_SPEECH_SYNTHESIS = new InjectionToken('[WA_SPEECH_SYNTHESIS]', {
  factory: () => inject(WINDOW).speechSynthesis
});
/**
 * @deprecated: drop in v5.0, use {@link WA_SPEECH_SYNTHESIS}
 */
const SPEECH_SYNTHESIS = WA_SPEECH_SYNTHESIS;
const WA_USER_AGENT = new InjectionToken('[WA_USER_AGENT]', {
  factory: () => inject(NAVIGATOR).userAgent
});
/**
 * @deprecated: drop in v5.0, use {@link WA_USER_AGENT}
 */
const USER_AGENT = WA_USER_AGENT;

/**
 * Generated bundle index. Do not edit.
 */

export { ANIMATION_FRAME, CACHES, CRYPTO, TOKEN_CSS as CSS, HISTORY, LOCAL_STORAGE, LOCATION, MEDIA_DEVICES, NAVIGATOR, NETWORK_INFORMATION, PAGE_VISIBILITY, PERFORMANCE, SCREEN, SESSION_STORAGE, SPEECH_RECOGNITION, SPEECH_SYNTHESIS, TOKEN_CSS, USER_AGENT, WA_ANIMATION_FRAME, WA_CACHES, WA_CRYPTO, WA_CSS, WA_HISTORY, WA_LOCAL_STORAGE, WA_LOCATION, WA_MEDIA_DEVICES, WA_NAVIGATOR, WA_NETWORK_INFORMATION, WA_PAGE_VISIBILITY, WA_PERFORMANCE, WA_SCREEN, WA_SESSION_STORAGE, WA_SPEECH_RECOGNITION, WA_SPEECH_SYNTHESIS, WA_USER_AGENT, WA_WINDOW, WINDOW };
