<h1 class="text-2xl font-bold text-gray-900">Your data</h1>
<p class="text-sm font-medium text-gray-500">
  This is the place where you can update your user data. Please make sure
  <strong>everything</strong> is filled in correctly.
</p>
<form (ngSubmit)="onSubmit()" [formGroup]="completeProfileForm">
  <div class="mt-12">
    <h2 class="text-base font-semibold leading-7 text-gray-900">
      Global data
    </h2>
    <p class="mt-1 text-sm leading-6 text-gray-500">
      This is you core user data. It will be used for every event you sign up
      for.
    </p>
  </div>
  <div class="mt-6 grid gap-4 lg:grid-cols-2">
    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstName" type="text" required />
      <mat-error>Your first name is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="lastName" type="text" required />
      <mat-error>Your last name is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> Communication Email</mat-label>
      <input
        formControlName="communicationEmail"
        matInput
        required
        type="email"
      />
      <mat-error>Your email is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Birthday</mat-label>
      <input matInput formControlName="birthdate" [matDatepicker]="picker" />
      <mat-hint>Use the calendar icon to pick your birthday</mat-hint>
      <mat-error>Your birthday is required</mat-error>
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
        data-cy="date-toggle"
      ></mat-datepicker-toggle>
      <mat-datepicker
        data-cy="date-picker"
        #picker
        startView="multi-year"
        [startAt]="startDate"
      ></mat-datepicker>
    </mat-form-field>
    <mat-checkbox formControlName="acceptTerms">
      I accept that my data will be processed according to the
      <a class="underline" routerLink="/page/privacy" target="_blank">
        Privacy Policy
      </a>
    </mat-checkbox>
  </div>
  <div class="mt-12">
    <h2 class="text-base font-semibold leading-7 text-gray-900">
      Your phone number
    </h2>
    <p class="mt-1 text-sm leading-6 text-gray-500">
      Optionally you can add your phone number. This will be used to contact you
      during events.
    </p>
  </div>
  <div class="mt-6 grid gap-6 lg:grid-cols-2 lg:gap-4">
    <mat-checkbox class="col-span-full" formControlName="acceptPhoneUsage">
      I wish that my phone number can be used to contact me during events.
    </mat-checkbox>
    @if (acceptPhoneUsage) {
      <mat-form-field>
        <mat-label>Phone number</mat-label>
        <input
          matInput
          formControlName="phone"
          type="tel"
          required
          pattern="^\+[1-9]\d{1,14}$"
        />
        <mat-hint
          >Enter your phone number in the international format
          (+49473634567)</mat-hint
        >
      </mat-form-field>
      <mat-checkbox formControlName="phoneNumberOnWhatsapp">
        My phone number is registered on WhatsApp, you can also contact me
        there.
      </mat-checkbox>
    }
  </div>

  <div class="mt-12">
    <h2 class="text-base font-semibold leading-7 text-gray-900">
      Section specific data
    </h2>
    <p class="mt-1 text-sm leading-6 text-gray-500">
      This additional data was requested by {{ tenantName() }}, the section you
      are signing up for. They need this data for their events.
    </p>
  </div>
  <app-form-display
    [formConfig]="formConfig()"
    [form]="additionalDataForm"
  ></app-form-display>
  <button
    [disabled]="completeProfileForm.invalid"
    color="primary"
    mat-flat-button
  >
    Save data
  </button>
</form>
