<app-reactive-toolbar>
  <mat-toolbar color="white">
    <app-back-button></app-back-button>
    <div resetScroll>Events with ratings</div>
  </mat-toolbar>
  @if ((events$ | async) === null) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
</app-reactive-toolbar>
<main class="p-4">
  <div class="mb-4 text-sm">Showing events of the last month.</div>
  @if (events$ | async; as events) {
    <table mat-table multiTemplateDataRows [dataSource]="events" class="w-full">
      <!--<ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/tenant/registrations/{{ element.id }}" mat-icon-button>
          <mat-icon svgIcon="icon-open-in-popup"></mat-icon>
        </a>
      </td>
    </ng-container>-->

      <ng-container matColumnDef="event">
        <th mat-header-cell *matHeaderCellDef>Event</th>
        <td mat-cell *matCellDef="let element">
          <app-event-chip
            [id]="element.id"
            [icon]="element.icon"
            [title]="element.title"
          ></app-event-chip>
        </td>
      </ng-container>

      <!--<ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/profile/{{ element.user.id }}">
          {{ element.user.fullName }}
        </a>
      </td>
    </ng-container>-->

      <!--<ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt | date: 'medium' }}
      </td>
    </ng-container>-->

      <!--<ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Payment Status</th>
      <td mat-cell *matCellDef="let element">
        {{ element.payment?.status }}
      </td>
    </ng-container>-->

      <ng-container matColumnDef="organizer">
        <th mat-header-cell *matHeaderCellDef>Organizer rating</th>
        <td mat-cell *matCellDef="let element">
          @if (element.organizerRatingCount > 0) {
            <span
              >{{ element.organizerRating | number: '1.1-2' }} ★ ({{
                element.organizerRatingCount
              }})</span
            >
          }
          @if (element.organizerVotes === 0) {
            <span>None</span>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="participant">
        <th mat-header-cell *matHeaderCellDef>Participant rating</th>
        <td mat-cell *matCellDef="let element">
          @if (element.participantRatingCount > 0) {
            <span
              >{{ element.participantRating | number: '1.1-2' }} ★ ({{
                element.participantRatingCount
              }})</span
            >
          }
          @if (element.voteCount === 0) {
            <span>None</span>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="comments">
        <!--      <th mat-header-cell *matHeaderCellDef>Participant rating</th>-->
        <td colspan="3" mat-cell *matCellDef="let element">
          @if (element.ratings.length) {
            <div class="my-4 flex flex-col flex-wrap gap-4 md:flex-row">
              @for (rating of element.ratings; track rating) {
                <app-rating-item
                  [isOrganizer]="rating.type === 'ORGANIZER'"
                  [comment]="rating.userComment"
                  [isAnonymous]="rating.anonymousRating"
                  [rating]="rating.rating"
                  [userId]="rating.user.id"
                  [userFullName]="rating.user.fullName"
                  [userPicture]="rating.user.picture"
                  [userStatus]="rating.user.currentTenant?.status || ''"
                ></app-rating-item>
              }
            </div>
          }
        </td>
      </ng-container>

      <!--<ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.payment?.netAmount ?? 0) / 100 | currency: 'EUR' }}
      </td>
    </ng-container>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: ['comments']"></tr>
    </table>
  }
  <!--<mat-paginator
    [length]="registrationCount$ | async"
    [pageSizeOptions]="[20, 50, 100, 500, 1000, 2500, 5000]"
    showFirstLastButtons
    (page)="updatePage($event)"
  >
  </mat-paginator>-->
</main>
