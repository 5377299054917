<header class="relative">
  <img
    ngSrc="/assets/images/home/charles_bridge.jpg"
    alt="Prague panorama"
    class="cover-image"
    height="2268"
    width="4032"
  />
  <div class="custom-shape-divider-bottom-1612976657">
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none"
    >
      <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
    </svg>
  </div>
</header>
<main>
  <div class="bg-gray-800 text-gray-50">
    <div class="p-4">
      <h1
        class="my-4 bg-gradient-to-br from-blue-300 to-blue-500 bg-clip-text text-3xl font-bold !leading-snug text-transparent md:mt-0 md:text-6xl"
      >
        Exchange, better!
      </h1>
      <p class="max-w-5xl leading-normal text-white md:text-lg">
        ESN CU Prague is here to make your international experience better. No
        matter if you are an incoming student, doing your international mobility
        or a local student wanting to broaden your horizon. <br />
        Our focus is the promotion of international spirit, tolerance in all
        facets of culture and the fostering of international understanding.
      </p>
      <div class="dark-button mt-4 flex max-w-md flex-row gap-2">
        <a mat-stroked-button routerLink="/page/faq">
          <mat-icon svgIcon="icon-faq"></mat-icon>
          Frequently Asked Questions
        </a>
      </div>
    </div>
  </div>
</main>
<footer class="relative p-4 pt-12 md:p-8">
  <div class="custom-shape-divider-top-1613324121">
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none"
    >
      <path
        d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
        class="shape-fill"
      ></path>
    </svg>
  </div>
  <h1
    class="my-8 bg-gradient-to-br from-fuchsia-600 to-fuchsia-800 bg-clip-text text-4xl font-bold leading-normal text-transparent md:text-6xl"
  >
    With our friends
  </h1>
  <div
    class="grid max-w-4xl grid-cols-1 place-items-center gap-8 md:grid-cols-2 lg:grid-cols-3"
  >
    <img
      class="p-4"
      ngSrc="/assets/logos/supporters/cuni.png"
      alt="CUNI Logo"
      height="390"
      width="740"
    />
    <img
      ngSrc="/assets/logos/esn_dark.svg"
      class="h-full max-h-full w-full max-w-full"
      alt="ESN Logo"
      height="16"
      width="16"
    />
    <img
      ngSrc="/assets/logos/supporters/dzs.png"
      alt="DZS Logo"
      height="2043"
      width="3508"
    />
  </div>
  <p class="mt-8 max-w-5xl leading-normal md:text-lg">
    We are only able to do what we do because of amazing support from public
    institutions, student associations and our university.
  </p>
  <h1
    class="my-8 bg-gradient-to-br from-fuchsia-600 to-fuchsia-800 bg-clip-text text-4xl font-bold leading-normal text-transparent md:text-6xl"
  >
    And commercial partners
  </h1>
  <div
    class="grid max-w-4xl grid-cols-1 place-items-center gap-8 md:grid-cols-2 lg:grid-cols-3"
  >
    <a href="https://cs-cz.facebook.com/cafelampicky/">
      <img
        class="p-4"
        ngSrc="/assets/logos/supporters/cu-prague/lampicky.jpg"
        alt="Lampicky Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://cryptocrew.cz/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/crypto_crew.png"
        class="h-full max-h-full w-full max-w-full"
        alt="crypto crew Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://cvapp.cz/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/cvapp.png"
        alt="cvapp Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://didacticus.cz/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/didacticus.png"
        alt="didacticus Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://forkys.eu/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/forkys.png"
        alt="forkys Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://www.facebook.com/bistrohabibi/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/habibi.jpg"
        alt="habibi Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://multisport.cz/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/multisport.png"
        alt="multisport Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://omep.cz/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/omep.png"
        alt="omep Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://https://pmclub.net/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/pmclub.png"
        alt="pmclub Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://www.presto.cz/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/presto.jpg"
        alt="presto Logo"
        height="390"
        width="740"
      />
    </a>
    <a href="https://questerland.cz/">
      <img
        ngSrc="/assets/logos/supporters/cu-prague/questerland.png"
        alt="questerland Logo"
        height="390"
        width="740"
      />
    </a>
  </div>
  <p class="mt-8 max-w-5xl leading-normal md:text-lg">
    You can learn more about our commercial partners
    <a href="https://esncuprague.cz/partners" style="color: blue">here</a>.
  </p>

  <app-technical-support></app-technical-support>
  <p class="mt-8 max-w-5xl leading-normal md:text-lg">
    Photo of night Prague credit: Marc-Olivier Giguere.
  </p>
</footer>
