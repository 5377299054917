<app-reactive-toolbar>
  <mat-toolbar color="white">
    <app-back-button></app-back-button>
    <div resetScroll>Events with booked spots</div>
  </mat-toolbar>
  @if ((events$ | async) === null) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
</app-reactive-toolbar>
<main class="p-4">
  @if (events$ | async; as events) {
    <table mat-table [dataSource]="events" class="w-fit sm:w-full">
      <!--<ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/tenant/registrations/{{ element.id }}" mat-icon-button>
          <mat-icon svgIcon="icon-open-in-popup"></mat-icon>
        </a>
      </td>
    </ng-container>-->

      <ng-container matColumnDef="event">
        <th mat-header-cell *matHeaderCellDef>Event</th>
        <td mat-cell *matCellDef="let element">
          <app-event-chip
            [id]="element.id"
            [icon]="element.icon"
            [title]="element.title"
          ></app-event-chip>
        </td>
      </ng-container>

      <!--<ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let element">
        <a routerLink="/profile/{{ element.user.id }}">
          {{ element.user.fullName }}
        </a>
      </td>
    </ng-container>-->

      <!--<ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt | date: 'medium' }}
      </td>
    </ng-container>-->

      <!--<ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Payment Status</th>
      <td mat-cell *matCellDef="let element">
        {{ element.payment?.status }}
      </td>
    </ng-container>-->

      <ng-container matColumnDef="spots">
        <th mat-header-cell *matHeaderCellDef>Maximum Spots</th>
        <td mat-cell *matCellDef="let element">
          {{ element.participantLimit }}
        </td>
      </ng-container>
      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef>Starting Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.start | date: 'medium' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="registrations">
        <th mat-header-cell *matHeaderCellDef>Registrations</th>
        <td mat-cell *matCellDef="let element">
          {{ element.participantRegistrationCount }}
          |
          {{ element.countedParticipantRegistrations }}
          {{
            element.participantRegistrationCount > element.participantLimit
              ? '⚠️'
              : ''
          }}
          {{
            element.participantRegistrationCount !==
            element.countedParticipantRegistrations
              ? '⚠‼️'
              : ''
          }}
        </td>
      </ng-container>

      <!--<ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.payment?.netAmount ?? 0) / 100 | currency: 'EUR' }}
      </td>
    </ng-container>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  }
  <!--<mat-paginator
    [length]="registrationCount$ | async"
    [pageSizeOptions]="[20, 50, 100, 500, 1000, 2500, 5000]"
    showFirstLastButtons
    (page)="updatePage($event)"
  >
  </mat-paginator>-->
</main>
