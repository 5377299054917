<h1 mat-dialog-title>Ticket</h1>
<mat-dialog-content>
  <img
    ngSrc="/qr/{{ data.id }}"
    alt="QR code here"
    width="200"
    height="200"
    class="w-full"
  />
  <p class="font-bold leading-normal">Ticket</p>
  <p class="leading-normal">{{ data.event }}</p>
  <p class="leading-normal">{{ data.user }}</p>
  <p class="text-xs text-zinc-400">
    <br />Please present this QR code to an organizer to check you in. It is
    <strong>not valid at third-party venues. </strong>
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-dialog-close mat-flat-button>close</button>
</mat-dialog-actions>
