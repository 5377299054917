<div class="bg-white p-4 lg:p-10">
  <div>
    <nav aria-label="Back" class="sm:hidden">
      <a
        class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        routerLink=".."
      >
        <svg
          aria-hidden="true"
          class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            clip-rule="evenodd"
            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
            fill-rule="evenodd"
          />
        </svg>
        Back
      </a>
    </nav>
    <nav aria-label="Breadcrumb" class="hidden sm:flex">
      <ol class="flex items-center space-x-4" role="list">
        <li>
          <div class="flex">
            <a
              class="text-sm font-medium text-gray-500 hover:text-gray-700"
              routerLink="/tenant"
            >
              Admin Tools
            </a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              aria-hidden="true"
              class="h-5 w-5 flex-shrink-0 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                clip-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                fill-rule="evenodd"
              />
            </svg>
            <span
              aria-current="page"
              class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              Users
            </span>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="min-w-0 flex-1">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
      >
        App user overview
      </h2>
    </div>
    <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
      <button
        (click)="gridComponent.exportData()"
        color="primary"
        mat-flat-button
        type="button"
      >
        Export Data
      </button>
    </div>
  </div>
</div>
<main class="container mx-auto mt-8 h-full px-4 sm:px-6 lg:px-8">
  <app-user-grid
    #gridComponent
    (rowClicked)="rowClicked($event)"
    height="70vh"
  ></app-user-grid>
</main>
