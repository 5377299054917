<div
  class="rounded border border-gray-300 p-2 transition-all hover:border-gray-700"
>
  <div class="flex flex-row gap-2">
    @for (rating of elements; track rating) {
      <mat-icon
        [class.saturate-0]="((currentValue | async) ?? 0) < rating"
        svgIcon="icon-star-2"
        (click)="setRating(rating)"
      ></mat-icon>
    }
  </div>
  <p class="text-sm italic text-gray-700">More stars are better</p>
</div>
