<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
  <mat-form-field class="w-full">
    <mat-label>Search to select</mat-label>
    <input
      matInput
      type="text"
      [formControl]="nameControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selectUser($event)"
      autoActiveFirstOption
    >
      @for (item of filteredChoices$ | async; track item) {
        <mat-option [value]="item.id">
          {{ item[data.displayAttribute] }}
        </mat-option>
      }
    </mat-autocomplete>
    <mat-hint>You can skip the search by pasting an ID</mat-hint>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
