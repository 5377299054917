@if (event) {
  <div class="my-2">
    <p class="mb-4 text-lg font-bold">
      {{ event.freeParticipantSpots }}
    </p>
    <app-check-additional-data
      [event]="event"
      (dataSubmission)="registerAdditionalData($event)"
    >
      @if (event.participantRegistrationPossible.option) {
        @if (
          !event.deRegistrationSettings?.participants?.deRegistrationPossible
        ) {
          <p>
            <span class="mr-2 font-bold text-red-500">Please note!</span>
            You can not deregister from this event.
            @if (event.deRegistrationSettings?.participants?.movePossible) {
              You can however give the spot you receive to someone else up until
              {{ lastMove | date }}.
              @if (!deRegistrationOptions?.refundFeesOnMove) {
                You will receive the participation fee but not the payment fees.
              }
            }
            @if (!event.deRegistrationSettings?.participants?.movePossible) {
              Your registration is final once completed.
            }
          </p>
        }
        @if (
          event.deRegistrationSettings?.participants?.deRegistrationPossible
        ) {
          <p>
            <span class="mr-2 font-bold">ℹ️ Registration info</span>
            You can cancel your registration until
            {{ lastDeRegistration | date }}.
            @if (!deRegistrationOptions?.refundFeesOnDeRegistration) {
              You will receive the participation fee but not the payment fees.
            }
          </p>
        }
        <p class="mb-2">
          We are showing all prices that you can choose from depending on the
          event and your account.
        </p>
        <mat-form-field class="mb-4">
          <mat-label>Price</mat-label>
          <mat-select [formControl]="priceControl">
            @for (price of availablePrices$ | async; track price) {
              <mat-option [value]="price">
                {{ price.amount | currency }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <br />
        @if (event.deferredPayment) {
          <button
            mat-raised-button
            color="primary"
            [disabled]="priceControl.invalid || (processing | async)"
            (click)="register()"
          >
            <mat-icon svgIcon="icon-money-bag-euro-2"></mat-icon>
            <span>Register for this event</span>
          </button>
        } @else {
          <button
            mat-raised-button
            color="primary"
            [disabled]="priceControl.invalid || (processing | async)"
            (click)="register()"
          >
            <mat-icon svgIcon="icon-money-bag-euro-2"></mat-icon>
            <span>Start payment and register</span>
          </button>
        }
        @if (processing | async) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
      }
    </app-check-additional-data>
    @if (
      !event.participantRegistrationPossible.option && !event.userIsRegistered
    ) {
      <p>
        {{ event.participantRegistrationPossible.reason }}
      </p>
    }
    @if (event.userIsRegistered) {
      <!--    <pre>{{ event.activeRegistration | json }}</pre>-->
      <!--    TODO: check with new transactions-->
      @if (activeStripePayment?.status !== 'incomplete') {
        <p> ✔️ You have a spot on this event. </p>
      }
      <!--    TODO: check with new transactions-->
      @if (activeStripePayment?.status === 'incomplete') {
        <p class="text-lg font-bold">⚠️ Your payment is not complete yet</p>
        <p>
          Please make sure to complete you payment by
          {{ lastPayment | date: 'medium' }} or your registration will be
          canceled automatically.
        </p>
        <div class="flex flex-col gap-4 md:flex-row">
          <button
            mat-flat-button
            color="primary"
            (click)="openPaymentSession(activeStripePayment?.checkoutUrl)"
          >
            Finalize event payment
          </button>
          <button mat-flat-button color="warn" (click)="cancelPayment()">
            Cancel your registration and payment
          </button>
        </div>
      }
      <!--    TODO: check with new transactions-->
      @if (activeStripePayment?.status === 'processing') {
        <p>
          Your payment is currently processing, once it was successful you can
          give the event away or give up you spot.
        </p>
      }
      @if (canMove.result) {
        <div class="my-2">
          <p class="mb-2">
            You can give this registration to another person until
            {{ lastMove | date }}.
            @if (!deRegistrationOptions?.refundFeesOnMove) {
              You will receive the participation fee but not the payment fees.
            }
          </p>
          <button mat-flat-button (click)="moveEvent()">Send event</button>
        </div>
      } @else if (!event.deferredPayment) {
        <p>
          You can not give this registration to another person:
          {{ canMove.reason }}
        </p>
      }
      @if (canDeregister.result) {
        <div class="my-2">
          <p class="mb-2">
            You can give up your spot on this event until
            {{ lastDeRegistration | date }}.
            @if (!deRegistrationOptions?.refundFeesOnDeRegistration) {
              You will receive the participation fee but not the payment fees.
            }
          </p>
          <button
            mat-flat-button
            color="warn"
            (click)="deregister()"
            [disabled]="processing | async"
          >
            Give up spot
          </button>
          @if (processing | async) {
            <mat-progress-bar
              class="mt-4"
              mode="indeterminate"
            ></mat-progress-bar>
          }
        </div>
      } @else if (!event.deferredPayment) {
        <p>
          You can not give up your spot on this event:
          {{ canDeregister.reason }}
        </p>
      }
    }
    @if (event.deferredPayment && !activeStripePayment) {
      <p>
        You have not yet paid for this event. If you are admitted, you will
        receive an email and will be able to finalize your registration by
        paying here.
      </p>
    }
  </div>
}
