<div
  class="relative flex flex-col items-center justify-center gap-8 bg-slate-200 p-8 lg:py-14"
>
  <div
    class="relative flex w-[90vw] max-w-[90vw] flex-col items-center overflow-hidden rounded-lg bg-white p-4 text-center shadow-lg sm:w-[400px]"
  >
    <div
      class="background absolute top-0 left-0 w-full h-[80px] bg-blue-500 flex items-center p-4 justify-between {{
        profile?.status | lowercase
      }}"
    >
      <img
        ngSrc="/assets/logos/esn_white.svg"
        alt="ESN Logo"
        class="w-20 object-contain md:w-24"
        height="16"
        width="16"
      />
    </div>
    @if (!profile) {
      <div
        class="z-10 h-[128px] w-[128px] overflow-hidden rounded-full bg-white"
      >
        <div class="skeleton h-full w-full"></div>
      </div>
    }
    @if (profile) {
      <img
        [ngSrc]="profile.picture"
        height="128"
        width="128"
        class="picture rounded-full h-[128px] w-[128px] border-white border-[3px] z-10 {{
          profile.status | lowercase
        }} bg-white"
        style="text-indent: -10000px"
        referrerpolicy="no-referrer"
        data-testid="profile-picture"
        onerror="this.src='assets/images/defaultUser.jpg'"
      />
    }
    <div class="mt-4 text-3xl font-bold">
      @if (!profile) {
        <div class="skeleton w-60">Phantom</div>
      }
      @if (profile) {
        <span>{{ profile.fullName }}</span>
      }
    </div>
    @if (profile && position(profile.position, profile.status); as position) {
      <div class="my-1">
        <span
          class="background px-2 py-1 text-sm text-white rounded-lg {{
            profile?.status | lowercase
          }}"
          >{{ position }}</span
        >
      </div>
    }
    @if (!profile) {
      <div class="skeleton mt-2 text-sm italic"> Phantom Country </div>
    }
    <div class="mt-3">
      @if (!profile) {
        <div class="skeleton h-20 w-60"></div>
      }
    </div>
    @if (profile && profile.organizedEventsCount > 0) {
      <mat-divider class="!my-4 w-full"></mat-divider>
      <div
        class="my-4 flex w-full flex-row justify-evenly sm:justify-center sm:gap-16"
      >
        <div>
          <div class="mb-1 text-3xl font-extrabold">
            {{ profile.organizedEventsCount }}
          </div>
          <div class="text-sm">
            event{{ profile.organizedEventsCount === 1 ? '' : 's' }} organized
          </div>
        </div>
        @if (profile.createdEventsCount > 0) {
          <div>
            <div class="mb-1 text-3xl font-extrabold">
              {{ profile.createdEventsCount }}
            </div>
            <div class="text-sm">
              event{{ profile.createdEventsCount === 1 ? '' : 's' }} created
            </div>
          </div>
        }
      </div>
    }
  </div>
  @if (profile) {
    <div class="right-4 top-4 block sm:absolute">
      <a
        mat-flat-button
        color="primary"
        [routerLink]="'/tenant/users/' + profile.id"
        *appIfRole="[Role.Admin]"
      >
        Manage user
      </a>
    </div>
  }
</div>
