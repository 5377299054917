<h1 mat-dialog-title>Add receipt</h1>
<mat-dialog-content>
  <form [formGroup]="uploadForm">
    <input
      type="file"
      accept="image/*,application/pdf"
      (change)="showPreview($event)"
      hidden
      #fileInput
    />

    <!-- Image Preview -->
    @if (previewURL$ | async; as url) {
      <div class="my-2 w-full">
        <img
          [src]="url"
          width="192"
          height="192"
          class="max-h-full object-cover"
        />
      </div>
    }
    @if (uploadProgress$ | async) {
      <mat-progress-bar
        mode="determinate"
        [value]="uploadProgress$ | async"
      ></mat-progress-bar>
    }

    <mat-form-field class="w-full">
      <mat-label>Amount</mat-label>
      <input matInput formControlName="amount" type="number" />
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-dialog-close mat-flat-button>Close</button>
  <button (click)="fileInput.click()" color="primary" mat-raised-button>
    Select file
  </button>
  <button
    mat-flat-button
    color="accent"
    [disabled]="uploadForm.invalid || (processing$ | async)"
    (click)="save()"
  >
    Save receipt
  </button>
</mat-dialog-actions>
