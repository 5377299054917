@if (newUI) {
  <app-nav></app-nav>
} @else {
  <app-navigation></app-navigation>
}

<div
  class="relative mb-12 flex min-h-screen flex-col sm:mb-0 sm:ml-[6rem] sm:w-[calc(100%-6rem)]"
>
  <router-outlet></router-outlet>
  <div class="grow"></div>
  <app-footer></app-footer>
</div>
