<app-reactive-toolbar>
  <mat-toolbar color="white">
    <app-back-button></app-back-button>
    <div resetScroll>Registration Codes</div>
  </mat-toolbar>
  @if ((codes$ | async) === null) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
</app-reactive-toolbar>
<main class="p-4">
  @if (codes$ | async; as orders) {
    <table mat-table [dataSource]="orders" class="w-full">
      <ng-container matColumnDef="event">
        <th mat-header-cell *matHeaderCellDef>Event</th>
        <td mat-cell *matCellDef="let element">
          <app-event-chip
            [id]="element.targetEvent.id"
            [icon]="element.targetEvent.icon"
            [title]="element.targetEvent.title"
          ></app-event-chip>
        </td>
      </ng-container>

      <ng-container matColumnDef="creator">
        <th mat-header-cell *matHeaderCellDef>Creator</th>
        <td mat-cell *matCellDef="let element">
          <app-user-chip
            size="sm"
            [id]="element.creator.id"
            [picture]="element.creator.picture"
            [name]="element.creator.fullName"
            [status]="element.creator.currentTenant?.status + ''"
          >
          </app-user-chip>
        </td>
      </ng-container>

      <ng-container matColumnDef="receiver">
        <th mat-header-cell *matHeaderCellDef>Receiver</th>
        <td mat-cell *matCellDef="let element">
          @if (element.registrationCreated?.user) {
            <app-user-chip
              size="sm"
              [id]="element.registrationCreated?.user?.id"
              [picture]="element.registrationCreated?.user?.picture"
              [name]="element.registrationCreated?.user?.fullName"
              [status]="
                element.registrationCreated?.user?.currentTenant?.status + ''
              "
            >
            </app-user-chip>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="used">
        <th mat-header-cell *matHeaderCellDef>Used</th>
        <td mat-cell *matCellDef="let element">
          {{ element.registrationCreated?.createdAt | date: 'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.status }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="cursor-pointer hover:bg-gray-100"
        [routerLink]="['.', row.id]"
      ></tr>
    </table>
  }
  <mat-paginator
    [length]="codesCount$ | async"
    [pageSizeOptions]="[20, 50, 100, 500, 1000, 2500, 5000]"
    showFirstLastButtons
    (page)="updatePage($event)"
  >
  </mat-paginator>
</main>
