<div
  class="sidenav fixed bottom-0 z-50 flex h-16 w-full flex-row bg-indigo-600 text-white sm:top-0 sm:h-full sm:w-[6rem] sm:flex-col"
  data-testid="navigation"
  (click)="preventProp($event)"
>
  @if (tenantInfo$ | async; as tenant) {
    @if (tenant?.settings?.brandIconUrl?.length) {
      <a
        matRipple
        class="mb-4 hidden h-[64px] min-h-[64px] w-full items-center bg-indigo-700 sm:flex"
        routerLink="/events"
      >
        <img
          class="p-4"
          width="192"
          height="192"
          [ngSrc]="tenant.settings.brandIconUrl ?? ''"
          alt="ESN Star"
        />
      </a>
    } @else {
      <a
        matRipple
        class="mb-4 hidden h-[64px] min-h-[64px] w-full items-center bg-indigo-700 sm:flex"
        routerLink="/events"
      >
        <img
          class="h-auto w-auto p-9"
          ngSrc="/assets/logos/star-white.svg"
          alt="ESN Star"
          height="16"
          width="16"
        />
      </a>
    }
  }
  <div
    class="flex h-full flex-1 flex-row items-center gap-0 sm:mx-2 sm:flex-col sm:gap-3"
  >
    @if (tenantInfo$ | async; as tenant) {
      @switch (tenant.homePageStrategy) {
        @case (HomePageStrategy.Static) {
          <a class="nav-item" routerLink="home" routerLinkActive="active">
            <mat-icon svgIcon="icon-home" matRipple></mat-icon>
            <span>Home</span>
          </a>
        }
        @case (HomePageStrategy.Markdown) {
          <a
            class="nav-item"
            routerLink="/page/about"
            routerLinkActive="active"
          >
            <mat-icon svgIcon="icon-home" matRipple></mat-icon>
            <span>Home</span>
          </a>
        }
        @case (HomePageStrategy.Link) {
          <a class="nav-item" [href]="tenant.homePageLink" target="_blank">
            <mat-icon svgIcon="icon-home" matRipple></mat-icon>
            <span>Home</span>
          </a>
        }
      }
    }
    <a
      class="nav-item"
      routerLink="events"
      routerLinkActive="active"
      data-testid="events-nav-item"
    >
      <mat-icon matRipple svgIcon="icon-overtime"></mat-icon>
      <span>Events</span>
    </a>
    <a
      class="nav-item"
      routerLink="section-hub"
      routerLinkActive="active"
      data-testid="tutor-hub-nav-item"
      *appIfStatus="[MembershipStatus.Full, MembershipStatus.Trial]"
    >
      <mat-icon svgIcon="icon-hub" matRipple></mat-icon>
      <span>Section Hub</span>
    </a>
    <a
      class="nav-item desktop-only"
      routerLink="event-templates"
      routerLinkActive="active"
      data-testid="event-templates-nav-item"
      *appIfStatus="[MembershipStatus.Full, MembershipStatus.Trial]"
    >
      <mat-icon svgIcon="icon-template" matRipple></mat-icon>
      <span>Event Templates</span>
    </a>
    <a
      class="nav-item"
      routerLink="tenant"
      routerLinkActive="active"
      data-testid="manage-nav-item"
      *appIfRole="[Role.Admin]"
    >
      <mat-icon matRipple svgIcon="icon-administrative-tools"></mat-icon>
      <span>Admin Tools</span>
    </a>
    <a
      *appIfRole="[Role.Admin]"
      class="nav-item"
      data-testid="manage-nav-item"
      routerLink="settings"
      routerLinkActive="active"
    >
      <mat-icon svgIcon="icon-maintenance" matRipple></mat-icon>
      <span>Settings</span>
    </a>
    @if ((tenantInfo$ | async)?.settings?.showPWAInstall) {
      @if (installEvent$ | async; as event) {
        <button class="nav-item" (click)="event.prompt()">
          <mat-icon svgIcon="icon-software-installer" matRipple></mat-icon>
          <span>Install the app</span>
        </button>
      }
    }

    <a
      *appIfGlobalAdmin
      class="nav-item"
      routerLink="global-admin"
      routerLinkActive="active"
    >
      <mat-icon matRipple svgIcon="icon-data-configuration"></mat-icon>
      <span>Global Admin</span>
    </a>

    <div class="hidden flex-1 sm:block"></div>
    <a
      class="nav-item flex h-10 w-10 justify-center p-1"
      matRipple
      routerLink="profile"
    >
      <app-auth-button></app-auth-button>
    </a>
  </div>
</div>
