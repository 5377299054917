<div
  matRipple
  [matTooltip]="size === 'fit' ? name : ''"
  class="chip {{ size }} {{
    status | lowercase
  }} box-border inline-flex flex-row items-center {{
    picture ? '' : 'pl-1'
  }} !touch-auto"
>
  @if (picture) {
    <div class="picture-container relative">
      <img
        [ngSrc]="picture"
        class="picture rounded-full z-10 {{ status | lowercase }} bg-white"
        height="450"
        width="450"
        style="text-indent: -10000px"
        referrerpolicy="no-referrer"
        onerror="this.src='assets/images/defaultUser.jpg'"
      />
      @if (checkedIn !== null) {
        @if (checkedIn) {
          <mat-icon
            svgIcon="icon-checkmark"
            class="absolute -right-3 bottom-0"
          ></mat-icon>
        } @else {
          <mat-icon
            svgIcon="icon-delete-sign"
            class="absolute -right-3 bottom-0"
          ></mat-icon>
        }
      }
    </div>
  }
  @if (name && size !== 'fit') {
    <div class="name-container flex max-w-[16em] flex-col">
      <div class="name flex items-center">
        <span class="overflow-hidden text-ellipsis whitespace-nowrap">{{
          name
        }}</span>
        @if (tag) {
          <span
            class="-mr-[0.2em] ml-1 rounded-lg bg-white px-1 text-xs font-normal"
            >{{ tag }}</span
          >
        }
      </div>
    </div>
  }
  <div (click)="$event.preventDefault(); $event.stopPropagation()">
    <ng-content></ng-content>
  </div>
</div>
