<h1 mat-dialog-title>Claim event with transfer code</h1>
<mat-dialog-content>
  <p class="leading-normal">
    You can claim an event here if you have received a code to do so. <br />
    When claiming the event we will charge you with the participation fee and
    return it to the user that signed up for this event initially.
  </p>
  <p class="my-2 rounded bg-gray-200 px-4 py-2 font-bold text-red-500">
    If you paid to receive the event code please contact us at
    @if (tenantInfo$ | async; as tenantInfo) {
      <a href="mailto:{{ tenantInfo.communicationEmail }}">{{
        tenantInfo.communicationEmail
      }}</a>
    }
    and provide us with details about this situation
  </p>
  <mat-form-field class="my-2 w-full">
    <mat-label>Event code</mat-label>
    <input
      matInput
      type="text"
      [pattern]="idTest"
      [formControl]="codeControl"
    />
    <mat-hint>Paste your event code here</mat-hint>
  </mat-form-field>
  @if (registrationCode$ | async; as code) {
    <h2>Claim {{ code.targetEvent.title }}</h2>
    @if (!code.registrationCreated) {
      <p>
        You can claim your registration to this event by paying the
        participation fee, please select from the available prices below.
      </p>
      <mat-form-field class="mb-4">
        <mat-label>Price</mat-label>
        <mat-select [formControl]="priceControl">
          @for (price of availablePrices$ | async; track price) {
            <mat-option [value]="price">
              {{ price.amount | currency }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <button
        mat-flat-button
        color="primary"
        [disabled]="processing$ | async"
        (click)="tryClaim()"
        class="w-full"
      >
        Claim this event
      </button>
    }
    @if (code.registrationCreated) {
      @if (code.registrationCreated.belongsToCurrentUser) {
        <p>You claimed this code already ✅</p>
        <!--        TODO: fix with new transactions-->
        <!--<ng-container
          *ngIf="
            code.registrationCreated?.transaction?.stripePayment?.status ===
            'incomplete'
          "
        >-->
        <p>
          Payment for this event is still missing, please finalize your
          registration by paying now
        </p>
        <!--          TODO: fix this button with new transactions-->
        <!--        <button-->
        <!--          mat-raised-button-->
        <!--          color="primary"-->
        <!--          (click)="-->
        <!--            openCheckout(-->
        <!--              code?.registrationCreated?.transactions[0]?.stripePayment-->
        <!--                ?.checkoutUrl ?? undefined-->
        <!--            )-->
        <!--          "-->
        <!--        >-->
        <!--          Pay for registration-->
        <!--        </button>-->
        <a mat-flat-button href="/events/{{ code?.targetEvent?.id }}"
          >Continue to event</a
        >
        <!--</ng-container>-->
      } @else {
        <p>
          This code was already claimed, it may become available again within
          the next hour
        </p>
      }
    }
  }
  @if (error$ | async; as error) {
    <div class="my-2 rounded bg-gray-200 px-4 py-2">
      <p class="mb-2 font-bold text-red-500">Error</p>
      <p>{{ error }}</p>
    </div>
  }
</mat-dialog-content>
