<div class="bg-white p-4 lg:p-10">
  <div>
    <nav aria-label="Breadcrumb" class="hidden sm:flex">
      <ol class="flex items-center space-x-4" role="list">
        <li>
          <div class="flex">
            <span class="text-sm font-medium text-gray-500 hover:text-gray-700">
              Admin Tools
            </span>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="min-w-0 flex-1">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
      >
        App Admin Tools
      </h2>
    </div>
    <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0"> </div>
  </div>
</div>
<main class="container mx-auto mt-8 h-full space-y-8 px-4 sm:px-6 lg:px-8">
  @if (hasCredits()) {
    <div class="rounded-md bg-green-50 p-4 shadow">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg
            class="h-5 w-5 text-green-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-green-800">
            You have credits remaining
          </h3>
          <div class="mt-2 text-sm text-green-700">
            <p>
              There will not be any deduction of application fees until your
              credits are used up.
            </p>
            <p>
              Your current balance is:
              <span class="font-bold">{{ credits() | currency }} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  }

  <div
    class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
  >
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-base font-semibold leading-6 text-gray-900">
        Statistics of the last Month
      </h3>
    </div>
    <dl
      class="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden md:grid-cols-3 md:divide-x md:divide-y-0"
    >
      <div class="px-4 py-5 sm:p-6">
        <dt class="text-base font-normal text-gray-900">
          New users Registered
        </dt>
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div
            class="flex items-baseline text-2xl font-semibold text-indigo-600"
          >
            {{ statistics()?.registeredUsers?.current | number }}
            <span class="ml-2 text-sm font-medium text-gray-500">
              from {{ statistics()?.registeredUsers?.before | number }}
            </span>
          </div>

          @if ((statistics()?.registeredUsers?.changePercentage ?? 0) > 0) {
            <div
              class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0"
            >
              <svg
                class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only"> Increased by </span>
              {{
                (statistics()?.registeredUsers?.changePercentage ?? 0) * 100
                  | number: '1.0-2'
              }}%
            </div>
          } @else {
            <div
              class="inline-flex items-baseline rounded-full bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800 md:mt-2 lg:mt-0"
            >
              <svg
                class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only"> Decreased by </span>
              {{
                (statistics()?.registeredUsers?.changePercentage ?? 0) * 100
                  | number: '1.0-2'
              }}%
            </div>
          }
        </dd>
      </div>
      <div class="px-4 py-5 sm:p-6">
        <dt class="text-base font-normal text-gray-900">Events started</dt>
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div
            class="flex items-baseline text-2xl font-semibold text-indigo-600"
          >
            {{ statistics()?.eventsStarted?.current | number }}
            <span class="ml-2 text-sm font-medium text-gray-500">
              from {{ statistics()?.eventsStarted?.before | number }}
            </span>
          </div>

          @if ((statistics()?.eventsStarted?.changePercentage ?? 0) > 0) {
            <div
              class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0"
            >
              <svg
                class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only"> Increased by </span>
              {{
                (statistics()?.eventsStarted?.changePercentage ?? 0) * 100
                  | number: '1.0-2'
              }}%
            </div>
          } @else {
            <div
              class="inline-flex items-baseline rounded-full bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800 md:mt-2 lg:mt-0"
            >
              <svg
                class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only"> Decreased by </span>
              {{
                (statistics()?.eventsStarted?.changePercentage ?? 0) * 100
                  | number: '1.0-2'
              }}%
            </div>
          }
        </dd>
      </div>
      <div class="px-4 py-5 sm:p-6">
        <dt class="text-base font-normal text-gray-900"
          >Participant registrations</dt
        >
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div
            class="flex items-baseline text-2xl font-semibold text-indigo-600"
          >
            {{ statistics()?.registeredParticipants?.current | number }}
            <span class="ml-2 text-sm font-medium text-gray-500">
              from {{ statistics()?.registeredParticipants?.before | number }}
            </span>
          </div>

          @if (
            (statistics()?.registeredParticipants?.changePercentage ?? 0) > 0
          ) {
            <div
              class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0"
            >
              <svg
                class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only"> Increased by </span>
              {{
                (statistics()?.registeredParticipants?.changePercentage ?? 0) *
                  100 | number: '1.0-2'
              }}%
            </div>
          } @else {
            <div
              class="inline-flex items-baseline rounded-full bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800 md:mt-2 lg:mt-0"
            >
              <svg
                class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only"> Decreased by </span>
              {{
                (statistics()?.registeredParticipants?.changePercentage ?? 0) *
                  100 | number: '1.0-2'
              }}%
            </div>
          }
        </dd>
      </div>
    </dl>
    <div class="px-4 py-4 text-gray-800 sm:px-6">
      <p>
        To learn more visit the
        <a class="font-bold hover:text-black" routerLink="stats"
          >Statistics page</a
        >
      </p>
    </div>
  </div>

  <div
    class="rounded-2xl bg-secondary-container p-4 text-primary-onContainer shadow-xl"
  >
    <h3>Human Resources</h3>
    <div class="flex flex-row flex-wrap gap-2">
      <a mat-stroked-button routerLink="users">Users</a>
    </div>
  </div>

  <div
    class="rounded-2xl bg-secondary-container p-4 text-primary-onContainer shadow-xl"
  >
    <h3>Event Management</h3>
    <div class="flex flex-row flex-wrap gap-2">
      <a mat-stroked-button routerLink="events">Events</a>
      <a mat-stroked-button routerLink="registrations"> Registrations </a>
      <a mat-stroked-button routerLink="codes"> Registration Codes </a>
      <a mat-stroked-button routerLink="ratings">Ratings</a>
      <a mat-stroked-button routerLink="bookings">Bookings</a>
      <a mat-stroked-button routerLink="event-template-categories">
        Event Template Categories
      </a>
      <a mat-stroked-button routerLink="organizers"> Organizations </a>
      <a mat-stroked-button routerLink="photos">Photos</a>
    </div>
  </div>

  <div
    class="rounded-2xl bg-secondary-container p-4 text-primary-onContainer shadow-xl"
  >
    <h3>Finance</h3>
    <div class="flex flex-row flex-wrap gap-2">
      <a mat-stroked-button routerLink="transactions"> Transactions </a>
      <a mat-stroked-button routerLink="purchases">Purchases</a>
      <a disabled mat-stroked-button routerLink="refunds"> Refunds </a>
    </div>
  </div>
</main>
