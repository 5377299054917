<header class="relative">
  <div
    class="absolute right-0 top-2 rounded-l-2xl border border-r-0 border-gray-600 bg-gray-800/50 p-2 backdrop-blur-lg backdrop-saturate-150 md:top-6"
  >
    <img
      ngSrc="/assets/logos/full_light.svg"
      alt="ESN TUMi Logo"
      class="h-12 md:h-24"
      height="16"
      width="192"
    />
  </div>
  <img
    ngSrc="/assets/images/home/tutors.jpg"
    alt="Picture of TUMI tutors at night"
    class="cover-image"
    height="2268"
    width="4032"
  />
  <div class="custom-shape-divider-bottom-1612976657">
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none"
    >
      <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
    </svg>
  </div>
</header>
<main>
  <div class="bg-gray-800 text-gray-50">
    <div class="p-4">
      <h1
        class="my-4 bg-gradient-to-br from-blue-300 to-blue-500 bg-clip-text text-3xl font-bold !leading-snug text-transparent md:mt-0 md:text-6xl"
      >
        Exchange, better!
      </h1>
      <p class="max-w-5xl leading-normal text-white md:text-lg">
        TUMi is here to make your international experience better. No matter if
        you are an incoming student, doing your international mobility or a
        local student wanting to broaden your horizon. <br />
        We are a registered Association (Verein) with close connections to our
        friends in the TUM Global & Alumni Office. Our focus is the promotion of
        international spirit, tolerance in all facets of culture and the
        fostering of international understanding.
      </p>
      <div class="dark-button mt-4 flex max-w-md flex-row gap-2">
        <a mat-stroked-button routerLink="/page/faq">
          <mat-icon svgIcon="icon-faq"></mat-icon>
          Frequently Asked Questions
        </a>
      </div>
      <h2 class="mt-8 text-2xl font-bold">Upcoming Events</h2>
    </div>
    <div class="flex w-full snap-x gap-12 overflow-x-auto pb-8 pt-4 sm:hidden">
      @for (event of events$ | async; track event) {
        <div
          routerLink="/events/{{ event.id }}"
          class="relative shrink-0 cursor-pointer snap-start scroll-ml-6 first:pl-6 last:pr-[calc(100%-21.5rem)]"
        >
          <div
            class="flex flex-col items-center rounded-2xl border bg-gray-600 p-4 text-center"
          >
            <img
              [ngSrc]="event.icon | iconURL"
              alt="{{ event.icon }}"
              height="64"
              width="64"
              class="w-16"
            />
            <h3>{{ event.title }}</h3>
            <p>{{ event.start | date: 'mediumDate' }}</p>
          </div>
        </div>
      }
    </div>
    <div class="hidden w-full p-4 sm:block">
      <app-grid minWidth="300px">
        @for (event of events$ | async; track event) {
          <div
            routerLink="/events/{{ event.id }}"
            class="flex cursor-pointer flex-col items-center rounded-2xl border bg-gray-600 p-4 text-center hover:bg-gray-700"
          >
            <img
              ngSrc="{{ event.icon | iconURL }}"
              alt="{{ event.icon }}"
              height="64"
              width="64"
              class="w-16"
            />
            <h3>{{ event.title }}</h3>
            <p>{{ event.start | date: 'mediumDate' }}</p>
          </div>
        }
      </app-grid>
    </div>
  </div>
</main>
<footer class="relative p-4 pt-12 md:p-8">
  <div class="custom-shape-divider-top-1613324121">
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 120"
      preserveAspectRatio="none"
    >
      <path
        d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
        class="shape-fill"
      ></path>
    </svg>
  </div>
  <h1
    class="my-8 bg-gradient-to-br from-fuchsia-600 to-fuchsia-800 bg-clip-text text-4xl font-bold leading-normal text-transparent md:text-6xl"
  >
    With friends
  </h1>
  <div
    class="grid max-w-4xl grid-cols-1 place-items-center gap-8 md:grid-cols-2 lg:grid-cols-3"
  >
    <img
      class="p-4"
      ngSrc="/assets/logos/supporters/tum.svg"
      alt="TUM Logo"
      height="390"
      width="740"
    />
    <img
      ngSrc="/assets/logos/esn_dark.svg"
      class="h-full max-h-full w-full max-w-full"
      alt="ESN Logo"
      height="16"
      width="16"
    />
    <img
      ngSrc="/assets/logos/supporters/lei.png"
      alt="LEI Logo"
      height="2043"
      width="3508"
    />
    <img
      ngSrc="/assets/logos/supporters/daad.svg"
      alt="DAAD Logo"
      class="h-full max-h-full w-full max-w-full"
      height="16"
      width="16"
    />
    <div>
      <span class="mx-auto text-sm">Supported by</span>
      <img
        ngSrc="/assets/logos/supporters/BMBF.svg"
        alt="BMBF Logo"
        class="h-full max-h-full w-full max-w-full"
        height="16"
        width="16"
      />
    </div>
    <div>
      <span class="mx-auto text-sm">Supported by</span>
      <img
        ngSrc="/assets/logos/supporters/AA.svg"
        alt="AA Logo"
        class="h-full max-h-full w-full max-w-full"
        height="139"
        width="211"
      />
    </div>
  </div>
  <p class="mt-8 max-w-5xl leading-normal md:text-lg">
    We are only able to do what we do because of amazing support from public
    institutions, student associations and our university.
  </p>
  <h1
    class="my-8 bg-fuchsia-700 bg-gradient-to-br bg-clip-text text-2xl font-bold leading-normal text-transparent md:text-3xl"
  >
    Partners
  </h1>
  <div
    class="grid max-w-4xl grid-cols-1 place-items-center gap-8 md:grid-cols-2 lg:grid-cols-3"
  >
    <a
      href="https://swapfiets.de/en-de/students?utm_source=esn_de&utm_medium=partnership&utm_campaign=introweeks2024"
    >
      <img
        alt="Swapfiets Logo"
        class="p-4"
        height="70"
        ngSrc="/assets/logos/partners/swapfiets.png"
        width="140"
      />
    </a>
  </div>
  <app-technical-support></app-technical-support>
</footer>
